export const photos = [
    // {
    //     id: 0,
    //     coordinates: [38.905174, -77.416056],
    //     heading: -5,
    //     filePath: 'photos/albion_bridge_site.jpg',
    //     subjectYear: 1740,
    //     description: 'Albion entry path bridge abutment earthworks, 2021.',
    //     type: 2,
    //     streetView: false,
    //     associatedLandmark: 78
    // },
    {
        id: 1,
        coordinates: [38.942402, -77.423973],
        heading: -40,
        filePath: 'photos/horsepen_bridge.jpg',
        subjectYear: 1860,
        description: 'Horsepen Farm entry path bridge, 2000s.',
        type: 1,
        streetView: false,
        associatedLandmark: 18
    },
    {
        id: 2,
        coordinates: [38.941509, -77.426136],
        heading: -180,
        filePath: 'photos/summers_house_ruins.jpg',
        subjectYear: 1860,
        description: 'Summers House ruins, 2000s.',
        type: 2,
        streetView: false,
        associatedLandmark: 18
    },
    {
        id: 3,
        coordinates: [38.91946125978687, -77.4186158180237],
        heading: -45,
        filePath: 'photos/peck_house_ruins.jpg',
        subjectYear: 1850,
        description: 'Halbert House ruins, 2009.',
        type: 2,
        streetView: true,
        associatedLandmark: 26
    },
    {
        id: 4,
        coordinates: [38.911085, -77.422218],
        heading: -50,
        filePath: 'photos/turley_hall_ruins.jpg',
        subjectYear: 1814,
        description: 'Turley Hall ruins, 2009.',
        type: 2,
        streetView: true,
        associatedLandmark: 29
    },
    {
        id: 5,
        coordinates: [38.910821, -77.420319],
        heading: -50,
        filePath: 'photos/turley_hall_1993.jpg',
        subjectYear: 1814,
        description: 'Clover Hill as seen from Lees Corner Rd, 1993.',
        type: 0,
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        associatedLandmark: 29
    },
    // {
    //     id: 6,
    //     coordinates: [38.909691, -77.440078],
    //     heading: 0,
    //     filePath: 'photos/current_leeton_upscaled.jpg',
    //     subjectYear: 1810,
    //     description: 'Leeton Site, 1997.',
    //     type: 2,
    //     streetView: false,
    //     associatedLandmark: 31
    // },
    {
        id: 7,
        coordinates: [38.909371, -77.44093],
        heading: 0,
        filePath: 'photos/leeton_loc_upscaled.jpg',
        subjectYear: 1810,
        description: 'Leeton, 1960.',
        attribution: 'Library of Congress',
        type: 0,
        streetView: false,
        associatedLandmark: 31
    },
    {
        id: 8,
        coordinates: [38.909617, -77.440008],
        heading: 0,
        filePath: 'photos/leeton_closeup_loc_1960.jpg',
        subjectYear: 1810,
        description: 'Leeton, 1960.',
        attribution: 'Library of Congress',
        type: 0,
        streetView: false,
        associatedLandmark: 31
    },
    {
        id: 9,
        coordinates: [38.94207063910428, -77.42634057998659],
        heading: 0,
        filePath: 'photos/summers_house_middleton_baby.jpg',
        subjectYear: 1861,
        description: 'Middleton child in front of Summers House, 1910s.',
        attribution: 'Clara M. Leigh',
        type: 0,
        streetView: false,
        associatedLandmark: 18
    },
    {
        id: 10,
        coordinates: [38.942328, -77.426055],
        heading: 0,
        filePath: 'photos/horsepen_farm_middleton.jpg',
        subjectYear: 1861,
        description: 'Middleton family at Horsepen Farm.',
        attribution: 'Clara M. Leigh',
        type: 0,
        streetView: false,
        associatedLandmark: 18
    },
    {
        id: 11,
        coordinates: [38.940237, -77.413304],
        heading: 0,
        filePath: 'photos/meetinghouse_1860s_peck.jpg',
        subjectYear: 1794,
        description: 'Frying Pan Baptist Church, late-19th Century.',
        attribution: 'Peck Collection',
        type: 0,
        streetView: true,
        associatedLandmark: 16
    },
    {
        id: 12,
        coordinates: [38.919347, -77.41877],
        heading: 0,
        filePath: 'photos/halbert_house_1940s.jpg',
        subjectYear: 1850,
        description: 'Halbert House, 1940s.',
        attribution: 'Peck Collection',
        type: 0,
        streetView: true,
        associatedLandmark: 26
    },
    {
        id: 13,
        coordinates: [38.907451, -77.430872],
        heading: 0,
        filePath: 'photos/sully_2009_gp.jpg',
        subjectYear: 1794,
        description: 'Sully House, 2010.',
        type: 1,
        streetView: false,
        associatedLandmark: 30
    },
    {
        id: 14,
        coordinates: [38.90589, -77.429472],
        heading: 0,
        filePath: 'photos/little_sully_bridge.jpg',
        subjectYear: 1850,
        description: 'Remains of bridge to Little Sully, 2010.',
        type: 2,
        streetView: false,
        associatedLandmark: 33
    },
    {
        id: 15,
        coordinates: [38.931848, -77.428862],
        heading: 0,
        filePath: 'photos/mclearen_farm_peck.jpg',
        subjectYear: 1850,
        description: 'McLearen Farm, mid-20th Century.',
        attribution: 'Peck Collection',
        streetView: true,
        associatedLandmark: 50,
        type: 0
    },
    {
        id: 16,
        coordinates: [38.906243, -77.474953],
        heading: 0,
        type: 1,
        filePath: 'photos/4433118959_0dfefa8069_o.jpg',
        subjectYear: 1785,
        description: 'John Hutchison House, 2009',
        streetView: true,
        associatedLandmark: 45
    },
    {
        id: 17,
        coordinates: [38.876843, -77.464097],
        heading: 0,
        type: 1,
        filePath: 'photos/4835568389_42b823f16e_o.jpg',
        subjectYear: 1820,
        description: 'Scott Family Cemetery, 2009',
        streetView: false,
        associatedLandmark: 41
    },
    {
        id: 19,
        coordinates: [38.941715, -77.450152],
        heading: 0,
        type: 0,
        filePath: 'photos/morris_farm.jpg',
        subjectYear: 1850,
        description: 'Rose Morris Farm, 1958.',
        streetView: false,
        associatedLandmark: 15,
        attribution: 'Courtesy of the Fairfax County Public Library.'
    },
    {
        id: 20,
        coordinates: [38.934636, -77.485656],
        heading: 0,
        type: 0,
        filePath: 'photos/cramer_farm.jpg',
        subjectYear: 1958,
        description: 'Cramer Farm, 1958.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        streetView: false
    },
    {
        id: 21,
        coordinates: [38.89196809844948, -77.45067358016969],
        heading: 0,
        type: 0,
        filePath: 'photos/hermitage_upscaled.jpg',
        subjectYear: 1776,
        description: 'The Hermitage, date unknown.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        streetView: false,
        associatedLandmark: 80
    },
    // {
    //     id: 22,
    //     coordinates: [38.892146, -77.451135],
    //     heading: 0,
    //     type: 2,
    //     filePath: 'photos/hermitage_now.jpg',
    //     subjectYear: 1776,
    //     description: 'Foundation of the Hermitage, 2021.',
    //     attribution: 'Thanks to New Life Christian Church.',
    //     streetView: false,
    //     associatedLandmark: 80
    // },
    {
        id: 23,
        coordinates: [38.893917, -77.431366],
        heading: 30,
        type: 0,
        filePath: 'photos/chantilly_upscaled.JPG',
        subjectYear: 1865,
        description: 'Chantilly Stores, 1920.',
        streetView: true
    },
    {
        id: 24,
        coordinates: [38.900186, -77.42881],
        heading: -10,
        type: 0,
        filePath: 'photos/little_sully_house.jpg',
        subjectYear: 1850,
        description: 'Little Sully House, 1970s.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        streetView: true,
        associatedLandmark: 33
    },
    // {
    //     id: 25,
    //     coordinates: [38.901083, -77.42909],
    //     heading: 0,
    //     type: 0,
    //     filePath: 'photos/little_sully_barns.jpg',
    //     subjectYear: 1850,
    //     description: 'Little Sully Barn Ruins, 1970s.',
    //     attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
    //     streetView: false,
    //     associatedLandmark: 33
    // },
    {
        id: 26,
        coordinates: [38.891693, -77.438885],
        heading: 0,
        type: 0,
        filePath: 'photos/possibly_avon.jpg',
        subjectYear: 1810,
        description: 'Unknown House at Avon, early 1970s.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        streetView: true,
        associatedLandmark: 81
    },
    {
        id: 27,
        coordinates: [38.906494, -77.474481],
        heading: 200,
        type: 1,
        filePath: 'photos/hutchison2.jpg',
        subjectYear: 1785,
        description: 'John Hutchison House, 2009.',
        streetView: true,
        associatedLandmark: 45
    },
    {
        id: 28,
        coordinates: [38.953587, -77.408379],
        heading: -20,
        type: 1,
        filePath: 'photos/merrybrook1.jpg',
        subjectYear: 1798,
        description: 'Merrybrook, 2010.',
        streetView: false,
        associatedLandmark: 6
    },
    {
        id: 29,
        coordinates: [38.953747, -77.408773],
        heading: 90,
        type: 1,
        filePath: 'photos/merrybrook2.jpg',
        subjectYear: 1798,
        description: 'Merrybrook, 2010.',
        streetView: false,
        associatedLandmark: 6
    },
    {
        id: 30,
        coordinates: [38.889712, -77.420602],
        heading: 200,
        type: 1,
        filePath: 'photos/mitchell.jpg',
        subjectYear: 1791,
        description: 'Mitchell-Weeks House, 2010.',
        streetView: true,
        associatedLandmark: 36
    },
    {
        id: 31,
        coordinates: [38.887493, -77.469367],
        heading: -20,
        type: 1,
        filePath: 'photos/hutchison_dam.jpg',
        subjectYear: 1850,
        description: 'Silas Hutchison Dam, 2010.',
        streetView: false,
        associatedLandmark: 44
    },
    {
        id: 32,
        coordinates: [38.94201, -77.415159],
        heading: -50,
        type: 0,
        filePath: 'photos/manley_hutchison_upscaled.JPG',
        subjectYear: 1860,
        description: 'Hutchison Mill, ca. 1900.',
        streetView: true,
        associatedLandmark: 14,
        attribution: 'Peck Collection'
    },
    // {
    //     id: 33,
    //     coordinates: [38.894586, -77.433026],
    //     heading: -60,
    //     type: 0,
    //     filePath: 'photos/chantilly_sw.jpg',
    //     subjectYear: 1958,
    //     description: 'Chantilly from SW, 1958.',
    //     streetView: true,
    //     attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.'
    // },
    {
        id: 34,
        coordinates: [38.921801, -77.445329],
        heading: -20,
        type: 2,
        filePath: 'photos/mana_dulles.jpg',
        subjectYear: 1851,
        description: 'Manassas Gap Railroad Bridge Abutment over Dead Run, 1974.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        streetView: false
    },
    {
        id: 35,
        coordinates: [38.890962, -77.42207],
        heading: 50,
        type: 0,
        filePath: 'photos/DOUG-054.jpg',
        subjectYear: 1860,
        description: 'Log Cabin, 1970s.',
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.'
    },
    {
        id: 36,
        coordinates: [38.967156, -77.513970],
        heading: 160,
        type: 1,
        filePath: 'photos/mankin_house.jpg',
        subjectYear: 1860,
        description: 'Stone Hut at Mankin Farm, 2010.',
        streetView: false,
        associatedLandmark: 103
    },
    {
        id: 37,
        coordinates: [38.890644, -77.422352],
        heading: 30,
        type: 0,
        filePath: 'photos/birch_house.jpg',
        subjectYear: 1860,
        description: 'E.W. Birch House, date unknown.',
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.'
    },
    {
        id: 38,
        coordinates: [38.890348, -77.421679],
        heading: 80,
        type: 0,
        filePath: 'photos/birch_shop.jpg',
        subjectYear: 1860,
        description: 'E.W. Birch\'s Blacksmith Shop, 1910s.',
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.'
    },
    {
        id: 40,
        coordinates: [38.911029, -77.421653],
        heading: -110,
        type: 0,
        filePath: 'photos/turley_hall_sunny.jpg',
        subjectYear: 1860,
        description: 'Turley Hall, 1970s.',
        streetView: true,
        associatedLandmark: 29,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.'
    },
    {
        id: 41,
        coordinates: [38.91959, -77.418493],
        heading: -110,
        type: 0,
        filePath: 'photos/peck_farm.jpg',
        subjectYear: 1860,
        description: 'Bonnyside Farm (Halbert/Stowell/Peck) House.',
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        associatedLandmark: 26
    },
    {
        id: 42,
        coordinates: [38.91997, -77.419244],
        heading: -45,
        type: 0,
        filePath: 'photos/peck_farm_barns.jpg',
        subjectYear: 1860,
        description: 'Bonnyside Farm Barns, 1980s.',
        streetView: false,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        associatedLandmark: 26
    },
    {
        id: 43,
        coordinates: [38.88427, -77.406188],
        heading: 110,
        type: 0,
        filePath: 'photos/thompson_house.jpg',
        subjectYear: 1860,
        description: 'Thompson House, date unknown.',
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.'
    },
    {
        id: 44,
        coordinates: [38.914689, -77.469914],
        heading: 0,
        type: 1,
        filePath: 'photos/orrison_rock.jpg',
        subjectYear: 1860,
        description: 'Fieldstone at Hutchison/Orrison Cemetery, 2000s',
        streetView: false
    },
    // {
    //     id: 45,
    //     coordinates: [38.897642, -77.498792],
    //     heading: 140,
    //     type: 0,
    //     filePath: 'photos/cage_hutchison_house.jpg',
    //     subjectYear: 1860,
    //     description: 'Cage Hutchison House, 2000s',
    //     streetView: true
    // },
    // {
    //     id: 46,
    //     coordinates: [38.91932, -77.493501],
    //     heading: 150,
    //     type: 0,
    //     filePath: 'photos/pleasant_valley_two_room.jpg',
    //     subjectYear: 1860,
    //     description: 'Two-room Pleasant Valley Schoolhouse, date unknown',
    //     streetView: true
    // },
    {
        id: 47,
        coordinates: [38.935269, -77.413823],
        heading: 30,
        type: 0,
        filePath: 'photos/floris_snowy_upscaled.jpg',
        subjectYear: 1860,
        description: 'Houses along Centreville Rd, 1960s',
        streetView: true,
        attribution: 'Courtesy of Pat Smith Johnson.'
    },
    // {
    //     id: 48,
    //     coordinates: [38.917429, -77.449886],
    //     heading: -15,
    //     type: 2,
    //     filePath: 'photos/woodgrove_site.jpg',
    //     subjectYear: 1860,
    //     description: 'Woodgrove site, 2021.',
    //     streetView: false
    // },
    {
        id: 49,
        coordinates: [38.92769, -77.46794],
        heading: 125,
        type: 0,
        filePath: 'photos/blevins_buildings.jpg',
        subjectYear: 1860,
        description: 'Blevins farm buildings, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 50,
        coordinates: [38.96786, -77.470692],
        heading: 50,
        type: 0,
        filePath: 'photos/mcculloch_buildings.jpg',
        subjectYear: 1800,
        description: 'Moran farm buildings, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 51,
        coordinates: [38.936569, -77.45042],
        heading: 4,
        type: 0,
        filePath: 'photos/herwig_house.jpg',
        subjectYear: 1850,
        description: 'Herwig house, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 52,
        coordinates: [38.932077, -77.434901],
        heading: 150,
        type: 0,
        filePath: 'photos/jackson_house.jpg',
        subjectYear: 1830,
        description: 'Jackson house, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 53,
        coordinates: [38.926745, -77.453924],
        heading: -100,
        type: 0,
        filePath: 'photos/bailey_farm_buildings.jpg',
        subjectYear: 1880,
        description: 'Bailey Farm entry, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 54,
        coordinates: [38.954315, -77.429451],
        heading: -45,
        type: 0,
        filePath: 'photos/beard_farm_house.jpg',
        subjectYear: 1860,
        description: 'Beard farm house, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 55,
        coordinates: [38.914346, -77.470257],
        heading: 30,
        type: 0,
        filePath: 'photos/elijah_hutchison_house.jpg',
        subjectYear: 1820,
        description: 'Lucy Hutchison house, 1960s',
        streetView: false,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.'
    },
    {
        id: 56,
        coordinates: [38.919232, -77.449829],
        heading: 60,
        type: 0,
        filePath: 'photos/woodgrove_1958.jpg',
        subjectYear: 1850,
        description: 'Mayhew house, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 57,
        coordinates: [38.931206, -77.434445],
        heading: -30,
        type: 0,
        filePath: 'photos/jackson_house_rear.jpg',
        subjectYear: 1830,
        description: 'Jackson house, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 58,
        coordinates: [38.936562, -77.449647],
        heading: -90,
        type: 0,
        filePath: 'photos/herwig_2.jpg',
        subjectYear: 1850,
        description: 'Herwig house, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 59,
        coordinates: [38.926758, -77.453912],
        heading: -90,
        type: 0,
        filePath: 'photos/bailey_farm_buildings.jpg',
        subjectYear: 1850,
        description: 'Bailey Farm Entrance, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 60,
        coordinates: [38.93336, -77.51259],
        heading: -70,
        type: 0,
        filePath: 'photos/dl_hutchison_house.jpg',
        subjectYear: 1860,
        description: 'D.L. Hutchison house, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 61,
        coordinates: [38.911468, -77.427758],
        heading: 15,
        type: 0,
        filePath: 'photos/coates_house.jpg',
        subjectYear: 1870,
        description: 'Coates House, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        associatedLandmark: 28
    },
    {
        id: 62,
        coordinates: [38.911967, -77.427334],
        heading: 200,
        type: 0,
        filePath: 'photos/coates_house_rear.jpg',
        subjectYear: 1870,
        description: 'Coates House, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        associatedLandmark: 28
    },
    {
        id: 63,
        coordinates: [38.930143, -77.439407],
        heading: -10,
        type: 0,
        filePath: 'photos/haselden_house.jpg',
        subjectYear: 1890,
        description: 'Haselden House, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 64,
        coordinates: [38.954596, -77.430322],
        heading: 0,
        type: 0,
        filePath: 'photos/beard_barn.jpg',
        subjectYear: 1860,
        description: 'Beard Dairy Barn, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 65,
        coordinates: [38.948235, -77.414419],
        heading: 120,
        type: 0,
        filePath: 'photos/coppermine_upscaled.jpg',
        subjectYear: 1860,
        description: 'George Howard house on Coppermine Road, 1980s',
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.'
    },
    {
        id: 66,
        coordinates: [38.939323, -77.413984],
        heading: 120,
        type: 0,
        filePath: 'photos/blossom-hill-upscaled.jpg',
        subjectYear: 1870,
        description: 'Blossom Hill, 1950s',
        streetView: true,
        attribution: 'Courtesy of Susan Rogers MacAuley.',
        associatedLandmark: 19
    },
    {
        id: 67,
        coordinates: [38.941457, -77.449033],
        heading: 120,
        type: 0,
        filePath: 'photos/rose_morris_upscaled.jpg',
        subjectYear: 1850,
        description: 'Rose Morris in front of House, 1958',
        streetView: false,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.'
    },
    {
        id: 68,
        coordinates: [38.970023, -77.471090],
        heading: 75,
        type: 0,
        filePath: 'photos/moran_upscaled.jpg',
        subjectYear: 1850,
        description: 'Moran House, 1958',
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.'
    },
    {
        id: 69,
        coordinates: [38.914077, -77.457256],
        heading: 300,
        type: 1,
        filePath: 'photos/1935House.jpg',
        subjectYear: 1890,
        description: 'House off of Stonecroft Blvd, 2009.',
        streetView: true
    },
    {
        id: 70,
        coordinates: [38.89158397038438, -77.45086669921876],
        heading: 0,
        type: 0,
        filePath: 'photos/hermitage_2.jpg',
        subjectYear: 1776,
        description: 'The Hermitage, 1976.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        streetView: false,
        associatedLandmark: 80
    },
    {
        id: 71,
        coordinates: [38.940405, -77.463179],
        heading: 175,
        type: 0,
        filePath: 'photos/stetson_newman_1-gigapixel-standard-scale-2_00x.jpg',
        subjectYear: 1950,
        description: 'Home of Stetson Newman, 1958.',
        attribution: 'Courtesy of the Thomas Balch Library.',
        streetView: false
    },
    {
        id: 72,
        coordinates: [38.940367, -77.464037],
        heading: 175,
        type: 0,
        filePath: 'photos/sherman_jett_1-gigapixel-standard-scale-2_00x.jpg',
        subjectYear: 1950,
        description: 'Home of Sherman Jett, 1958.',
        attribution: 'Courtesy of the Thomas Balch Library.',
        streetView: false
    },
    {
        id: 73,
        coordinates: [38.9398, -77.463957],
        heading: -5,
        type: 0,
        filePath: 'photos/sherman_jett_2-gigapixel-standard-scale-2_00x.jpg',
        subjectYear: 1950,
        description: 'Home of Sherman Jett, 1958.',
        attribution: 'Courtesy of the Thomas Balch Library.',
        streetView: false
    },
    {
        id: 74,
        coordinates: [38.938444, -77.459059],
        heading: 140,
        type: 0,
        filePath: 'photos/welford_newman_2-gigapixel-standard-scale-2_00x.jpg',
        subjectYear: 1950,
        description: 'Tenant House owned by Welford Newman, 1958.',
        attribution: 'Courtesy of the Thomas Balch Library.',
        streetView: false
    },
    {
        id: 75,
        coordinates: [38.939207, -77.460298],
        heading: 160,
        type: 0,
        filePath: 'photos/welford_newman_1-gigapixel-standard-scale-2_00x.jpg',
        subjectYear: 1950,
        description: 'Welford Newman house, 1958.',
        attribution: 'Courtesy of the Thomas Balch Library.',
        streetView: false
    },
    {
        id: 76,
        coordinates: [38.939875, -77.461073],
        heading: 220,
        type: 0,
        filePath: 'photos/AllenRaw-gigapixel-standard-scale-2_00x.jpg',
        subjectYear: 1950,
        description: 'Edward Allen House, 1958.',
        attribution: 'Courtesy of the Thomas Balch Library.',
        streetView: false
    },
    {
        id: 77,
        coordinates: [38.938551, -77.486400],
        heading: 35,
        type: 0,
        filePath: 'photos/CramerSmall-gigapixel-standard-scale-2_00x.jpg',
        subjectYear: 1850,
        description: 'Edison Cramer Tenant House, 1958.',
        attribution: 'Courtesy of the Thomas Balch Library.',
        streetView: false
    },
    {
        id: 78,
        coordinates: [38.94037, -77.46190],
        heading: 35,
        type: 0,
        filePath: 'photos/shiloh_baptist_upscaled.jpg',
        subjectYear: 1890,
        description: 'Shiloh Baptist Church, 1958.',
        attribution: 'Peck Collection.',
        streetView: false
    },
    {
        id: 79,
        type: 0,
        filePath: 'photos/avon_aerial.png',
        subjectYear: 1810,
        description: 'Oblique aerial photograph of Avon, 1937.',
        attribution: 'USDA',
        streetView: false
    },
    {
        id: 80,
        type: 0,
        filePath: 'photos/theodorick_lee.jpg',
        description: 'Courtesy of Sara Fielder.',
        attribution: 'USDA',
        streetView: false
    },
    {
        id: 81,
        type: 0,
        filePath: 'photos/jacob_haight.jpg',
        description: 'Jacob Haight.',
        attribution: 'Fairfax County Public Library',
        streetView: false
    },
    {
        id: 82,
        type: 0,
        coordinates: [38.9386107, -77.4135287],
        filePath: 'photos/bowman_house.jpg',
        description: 'Bowman Store, 2007',
        subjectYear: 1890,
        streetView: true,
        heading: 100
    },
    {
        id: 83,
        type: 0,
        coordinates: [38.9242728, -77.5052908],
        filePath: 'photos/vacant.jpg',
        description: 'House in Pleasant Valley, 2009',
        subjectYear: 1850,
        streetView: true,
        heading: 25
    },
    {
        id: 84,
        type: 0,
        coordinates: [38.9197876, -77.4689848],
        filePath: 'photos/water_works.jpg',
        description: 'House in Pleasant Valley, 2009',
        subjectYear: 1890,
        streetView: true,
        heading: -50
    },
    {
        id: 85,
        type: 0,
        coordinates: [38.894239, -77.430404],
        filePath: 'photos/chantilly_cabins.jpg',
        description: 'Rental Cabins at Woodruff\'s Corner, 1960s',
        subjectYear: 1950,
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        heading: -40
    },
    {
        id: 86,
        type: 0,
        coordinates: [38.946125, -77.416616],
        filePath: 'photos/keyes_house.jpg',
        description: 'Keyes/Cook House, 2009',
        subjectYear: 1884,
        streetView: true,
        heading: -45
    },
    {
        id: 87,
        type: 1,
        coordinates: [38.8990157, -77.4423174],
        filePath: 'photos/bellecook.jpg',
        description: 'Belle Cook Building, Chantilly Baptist Church, 2008',
        subjectYear: 1880,
        streetView: true,
        heading: 45
    },
    {
        id: 88,
        type: 1,
        coordinates: [38.9448757, -77.4234410],
        filePath: 'photos/murphy_farm.jpg',
        description: 'Murphy Farm Barn, 2009',
        subjectYear: 1850,
        streetView: true,
        heading: 0
    },
    {
        id: 89,
        type: 1,
        coordinates: [38.9480194, -77.4140126],
        filePath: 'photos/floris_presbyterian.jpg',
        description: 'Floris Presbyterian Church building, 2009',
        subjectYear: 1890,
        streetView: true,
        heading: -25
    },
    {
        id: 90,
        type: 1,
        coordinates: [38.9139634, -77.4809866],
        filePath: 'photos/PVUMC.jpg',
        description: 'Pleasant Valley Methodist Church, 2009',
        subjectYear: 1895,
        streetView: true,
        heading: -135
    },
    {
        id: 91,
        type: 0,
        coordinates: [38.9595088, -77.4980117],
        filePath: 'photos/OldOxHouse.jpg',
        description: 'House off of Old Ox Road, 2009',
        subjectYear: 1900,
        streetView: true,
        heading: 180
    },
    {
        id: 92,
        type: 1,
        coordinates: [38.9684777, -77.4845143],
        filePath: 'photos/beaver_meadow_houses.jpg',
        description: 'Abandoned houses off of Beaver Meadow Rd, 2009',
        subjectYear: 1950,
        streetView: true,
        heading: 90
    },
    {
        id: 93,
        type: 0,
        coordinates: [38.9258613, -77.5104143],
        filePath: 'photos/Dilap.jpg',
        description: 'House off of Route 50, 2009.',
        subjectYear: 1930,
        streetView: true,
        heading: -45
    },
    {
        id: 94,
        type: 0,
        coordinates: [38.9515973, -77.5348383],
        filePath: 'photos/arcola_house.jpg',
        description: 'Lanham House, 2009.',
        subjectYear: 1888,
        streetView: true,
        heading: -90
    },
    {
        id: 95,
        type: 1,
        coordinates: [38.9359751, -77.4097566],
        filePath: 'photos/Ellmore.jpg',
        description: 'Ellmore House, 2009',
        subjectYear: 1895,
        streetView: true,
        heading: 45
    },
    {
        id: 96,
        type: 0,
        coordinates: [38.938181, -77.47000],
        filePath: 'photos/Blackwelder1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Blackwelder House, 1958',
        subjectYear: 1770,
        streetView: false,
        heading: -180,
        attribution: 'Courtesy of the Thomas Balch Library.',
        associatedLandmark: 141
    },
    {
        id: 97,
        type: 0,
        coordinates: [38.937743, -77.470644],
        filePath: 'photos/Blackwelder2-gigapixel-standard-scale-2_00x.jpg',
        description: 'Blackwelder House, 1958',
        subjectYear: 1770,
        streetView: false,
        heading: 65,
        attribution: 'Courtesy of the Thomas Balch Library.',
        associatedLandmark: 141
    },
    {
        id: 98,
        type: 0,
        coordinates: [38.95231, -77.44978],
        filePath: 'photos/Vierling b113-gigapixel-standard-scale-2_00x.jpg',
        description: 'Red Door Farm, 1958',
        subjectYear: 1870,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -90
    },
    {
        id: 99,
        type: 0,
        coordinates: [38.95862, -77.44918],
        filePath: 'photos/Kirkwood1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Kirkwood House, 1958',
        subjectYear: 1895,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 50
    },
    {
        id: 100,
        type: 0,
        coordinates: [38.95867, -77.44681],
        filePath: 'photos/Kirkwood2-gigapixel-standard-scale-2_00x.jpg',
        description: 'Kirkwood House, 1958',
        subjectYear: 1895,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -45
    },
    {
        id: 101,
        type: 0,
        coordinates: [38.94169, -77.45087],
        filePath: 'photos/BaileyPano-gigapixel-standard-scale-2_00x.jpg',
        description: 'Bailey House, 1958',
        subjectYear: 1840,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -50,
        associatedLandmark: 142
    },
    {
        id: 102,
        type: 0,
        coordinates: [38.93275, -77.45544],
        filePath: 'photos/Wrenn1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Wrenn House, 1958',
        subjectYear: 1820,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 50
    },
    {
        id: 103,
        type: 0,
        coordinates: [38.93286, -77.45642],
        filePath: 'photos/Wrenn2-gigapixel-standard-scale-2_00x.jpg',
        description: 'Wrenn Farm, 1958',
        subjectYear: 1820,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 95
    },
    {
        id: 104,
        type: 0,
        coordinates: [38.94226, -77.48357],
        filePath: 'photos/Zackrison2-gigapixel-standard-scale-2_00x.jpg',
        description: 'Zackrison Farm, 1958',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 20
    },
    {
        id: 105,
        type: 0,
        coordinates: [38.94273, -77.4842],
        filePath: 'photos/Zackrison1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Zackrison House, 1958',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 55
    },
    {
        id: 106,
        type: 0,
        coordinates: [38.93998, -77.47164],
        filePath: 'photos/Savoy2-gigapixel-standard-scale-2_00x.jpg',
        description: 'Savoy House, 1958',
        subjectYear: 1870,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -45
    },
    {
        id: 107,
        type: 1,
        coordinates: [38.93845729458132, -77.41321921348573],
        filePath: 'photos/HouseChurch.jpg',
        description: 'Lee House & Floris Methodist Church, 2010',
        subjectYear: 1890,
        streetView: true,
        heading: 90
    },
    {
        id: 108,
        type: 0,
        coordinates: [38.95089, -77.518045],
        filePath: 'photos/Arcola2.jpg',
        description: 'Dowden House, post-relocation, 2010',
        subjectYear: 1860,
        streetView: false,
        heading: 90
    },
    {
        id: 109,
        type: 1,
        coordinates: [38.95168, -77.534660],
        filePath: 'photos/arcola_house_2.jpg',
        description: 'House, 2010',
        subjectYear: 1860,
        streetView: true,
        heading: 115
    },
    {
        id: 110,
        type: 0,
        coordinates: [38.919138329469774, -77.45623759122266],
        filePath: 'photos/Rector1-gigapixel-standard-scale-4_00x.jpg',
        description: 'Willard Rd looking north, by Rector House, 1958',
        subjectYear: 1940,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 5
    },
    {
        id: 111,
        type: 0,
        coordinates: [38.92184021586989, -77.45563030242921],
        filePath: 'photos/Delay1-gigapixel-standard-scale-2_00x.jpg',
        description: 'DeLay house, 1958',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -40
    },
    {
        id: 112,
        type: 0,
        coordinates: [38.92188195122959, -77.45607018470766],
        filePath: 'photos/Delay2-gigapixel-standard-scale-2_00x.jpg',
        description: 'DeLay house, rear, 1958',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 90
    },
    {
        id: 113,
        type: 0,
        coordinates: [38.92673143285888, -77.45473980903627],
        filePath: 'photos/bailey_house_1.jpg',
        description: 'Bailey House, 1958 (Colorized)',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -35,
        associatedLandmark: 142
    },
    {
        id: 114,
        type: 0,
        coordinates: [38.94162836894871, -77.45117783546449],
        filePath: 'photos/willard_store.jpg',
        description: 'Willard Store, 1958 (Colorized)',
        subjectYear: 1900,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -10,
        associatedLandmark: 142
    },
    {
        id: 115,
        type: 0,
        coordinates: [38.910111607145325, -77.43950486183168],
        filePath: 'photos/leeton_north-gigapixel-standard-scale-4_00x.jpg',
        description: 'Leeton, 1958',
        subjectYear: 1820,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -140,
        associatedLandmark: 31
    },
    {
        id: 116,
        type: 0,
        coordinates: [38.94036830111461, -77.46203541755678],
        filePath: 'photos/shiloh-gigapixel-standard-scale-2_00x.jpg',
        description: 'Shiloh Baptist Church, 1958',
        subjectYear: 1900,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -25
    },
    {
        id: 117,
        type: 0,
        coordinates: [38.941194440831836, -77.46154189109804],
        filePath: 'photos/anna_allen-gigapixel-standard-scale-2_00x.jpg',
        description: 'Anna Allen House, 1958',
        subjectYear: 1900,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 100,
        associatedLandmark: 31
    },
    {
        id: 118,
        type: 0,
        coordinates: [38.92822544043721, -77.45323777198793],
        filePath: 'photos/blair1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Blair House, 1958',
        subjectYear: 1920,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 100
    },
    {
        id: 119,
        type: 0,
        coordinates: [38.92805016743429, -77.45240092277528],
        filePath: 'photos/blair2-gigapixel-standard-scale-2_00x.jpg',
        description: 'Blair House, 1958',
        subjectYear: 1920,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -30
    },
    {
        id: 120,
        type: 0,
        coordinates: [38.926718, -77.467177],
        filePath: 'photos/blevinsrear-gigapixel-standard-scale-2_00x.jpg',
        description: 'Blevins House, 1958',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -30
    },
    {
        id: 121,
        type: 0,
        coordinates: [38.93927, -77.47714],
        filePath: 'photos/cooperfront-gigapixel-standard-scale-2_00x.jpg',
        description: 'Cooper House, 1958',
        subjectYear: 1890,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 20
    },
    {
        id: 122,
        type: 0,
        coordinates: [38.93969, -77.47677],
        filePath: 'photos/cooperrear-gigapixel-standard-scale-2_00x.jpg',
        description: 'Cooper House, 1958',
        subjectYear: 1890,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -120
    },
    {
        id: 123,
        type: 0,
        coordinates: [38.94075, -77.455208],
        filePath: 'photos/henderson1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Henderson House, 1958',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -80
    },
    {
        id: 124,
        type: 0,
        coordinates: [38.94917996182595, -77.48903989791872],
        filePath: 'photos/michael1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Lawrence Michael House, 1958',
        subjectYear: 1890,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 80
    },
    {
        id: 125,
        type: 0,
        coordinates: [38.94938855794633, -77.48877167701723],
        filePath: 'photos/michael2-gigapixel-standard-scale-2_00x.jpg',
        description: 'Lawrence Michael House, 1958',
        subjectYear: 1890,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -120
    },
    {
        id: 126,
        type: 0,
        coordinates: [38.95256748685516, -77.45021224021913],
        filePath: 'photos/vierling1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Vierling House, 1958',
        subjectYear: 1890,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -20
    },
    {
        id: 127,
        type: 0,
        coordinates: [38.955613, -77.465806],
        filePath: 'photos/moore1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Moore House, 1958',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -90
    },
    {
        id: 128,
        type: 0,
        coordinates: [38.955229, -77.465978],
        filePath: 'photos/moore2-gigapixel-standard-scale-2_00x.jpg',
        description: 'Moore House, 1958',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: 0
    },
    {
        id: 129,
        type: 0,
        coordinates: [38.913316, -77.440226],
        filePath: 'photos/jakenorris-gigapixel-standard-scale-2_00x.jpg',
        description: 'Jake Norris House, 1958',
        subjectYear: 1940,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -20
    },
    {
        id: 130,
        type: 0,
        coordinates: [38.9460843232509, -77.43096470832826],
        filePath: 'photos/hydetenant-gigapixel-standard-scale-2_00x.jpg',
        description: 'Hyde Tenant House, 1958',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -80,
        associatedLandmark: 12
    },
    {
        id: 131,
        type: 0,
        coordinates: [38.94666841634807, -77.43387222290039],
        filePath: 'photos/hyde1-gigapixel-standard-scale-2_00x.jpg',
        description: 'Hyde House, 1958',
        subjectYear: 1820,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -15,
        associatedLandmark: 12
    },
    {
        id: 132,
        type: 0,
        coordinates: [38.946735169538435, -77.43371129035951],
        filePath: 'photos/hyde2-gigapixel-standard-scale-2_00x.jpg',
        description: 'Hyde House, 1958',
        subjectYear: 1820,
        streetView: false,
        attribution: 'Courtesy of the Thomas Balch Library.',
        heading: -90,
        associatedLandmark: 12
    },
    {
        id: 133,
        type: 1,
        coordinates: [38.94490777825935, -77.4173927307129],
        filePath: 'photos/IMG_5335.jpg',
        description: 'Mosby\'s Rock, 2010',
        subjectYear: 1860,
        streetView: false,
        heading: 20,
        associatedLandmark: 138
    },
    {
        id: 134,
        type: 1,
        coordinates: [38.89200150079174, -77.46650934219362],
        filePath: 'photos/pdc_lee_barn.jpg',
        description: 'Lee Barn, 2021',
        subjectYear: 1937,
        streetView: false,
        heading: -90
    },
    {
        id: 135,
        type: 0,
        coordinates: [38.93817355996266, -77.41397023200989],
        filePath: 'photos/walker_house.jpg',
        description: 'Walker House, 2000',
        attribution: 'Courtesy of Megan Parrish.',
        subjectYear: 1900,
        streetView: false,
        heading: -10
    },
    {
        id: 136,
        type: 0,
        coordinates: [38.894314574784936, -77.43024587631227],
        filePath: 'photos/thompson_cabins.jpg',
        description: 'Rental Cabins at Woodruff\'s Corner, 1960s',
        subjectYear: 1950,
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        heading: 15
    },
    {
        id: 137,
        type: 0,
        coordinates: [38.9675924622857, -77.44956851005554],
        filePath: 'photos/colemans_school.jpg',
        description: 'Coleman\'s School, date unknown.',
        subjectYear: 1950,
        streetView: true,
        attribution: 'Peck Collection.',
        heading: 110
    },
    {
        id: 138,
        type: 0,
        coordinates: [38.8972788735571, -77.43880748748781],
        filePath: 'photos/CHAN-013_2.jpg',
        description: 'Barlow/Groseclose House on Route 50, 1960s',
        subjectYear: 1950,
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        heading: 200
    },
    {
        id: 139,
        type: 0,
        coordinates: [38.94835391516064, -77.4139165878296],
        filePath: 'photos/FLOR-001-2.jpg',
        description: 'McNair Farm, 1900s',
        subjectYear: 1860,
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        heading: 65
    },
    {
        id: 140,
        type: 0,
        coordinates: [38.94537506087722, -77.41742491722107],
        filePath: 'photos/martin_house.jpg',
        description: 'Brown House, 1985',
        subjectYear: 1860,
        streetView: true,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        heading: 155
    },
    {
        id: 141,
        type: 0,
        coordinates: [38.94570883228996, -77.42383003234865],
        filePath: 'photos/murphy_upscaled.jpg',
        description: 'Murphy House, 1985',
        subjectYear: 1850,
        streetView: false,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        heading: 140
    },
    {
        id: 142,
        type: 0,
        coordinates: [38.94242111538144, -77.41567611694336],
        filePath: 'photos/waggle_brown_upscaled.jpg',
        description: 'Coleman/Manly Hutchison House, 1985',
        subjectYear: 1860,
        streetView: false,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        heading: 300
    },
    {
        id: 143,
        type: 0,
        coordinates: [38.88493655573842, -77.46003985404968],
        filePath: 'photos/james_farmington.jpg',
        description: 'James House, 1985',
        subjectYear: 1900,
        streetView: false,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        heading: 180
    },
    {
        id: 144,
        type: 0,
        filePath: 'photos/Laura_Ratcliffe_Portrait.jpg',
        description: 'Laura Ratcliffe.',
        attribution: 'Library of Congress',
        streetView: false
    },
    {
        id: 145,
        type: 2,
        subjectYear: 1776,
        heading: 250,
        filePath: 'photos/PanoFoundationW.jpg',
        description: 'Foundation of the looking west.',
        attribution: 'Thanks to New Life Christian Church',
        coordinates: [38.89220, -77.45125],
        streetView: false
    },
    {
        id: 146,
        type: 0,
        filePath: 'photos/blue_ridge_airfield.JPG',
        description: 'Aerial photograph of Blue Ridge Airport from the 1945 Army Air Force Directory.',
        streetView: false
    },
    // {
    //     id: 147,
    //     type: 0,
    //     subjectYear: 1860,
    //     coordinates: [38.925054, -77.417795],
    //     filePath: 'photos/patton_shed_remains.jpg',
    //     description: 'Remains of shed at Patton/Janney Farm.',
    //     streetView: false,
    //     associatedLandmark: 25
    // },
    {
        id: 148,
        type: 2,
        coordinates: [38.942488, -77.415483],
        filePath: 'photos/manley_now.jpg',
        description: 'Site of Coleman/Manley Hutchison House, 2023',
        subjectYear: 1860,
        streetView: false,
        heading: 300
    },
    {
        id: 149,
        type: 0,
        subjectYear: 1860,
        coordinates: [38.94228760081348, -77.41591215133668],
        filePath: 'photos/waggle_brown_2.jpg',
        description: 'Coleman/Manly Hutchison House, 1985',
        streetView: false,
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        heading: 0

    },
    {
        id: 150,
        type: 0,
        subjectYear: 1900,
        coordinates: [38.921447902288726, -77.49704360961915],
        filePath: 'photos/pleasant_valley_hidden_gp.jpg',
        description: 'Barn in Pleasant Valley, 2009',
        streetView: true,
        heading: 300
    },
    {
        id: 151,
        type: 0,
        filePath: 'photos/richard_cockerille.jpg',
        description: 'Richard Cockerille'
    },
    {
        id: 152,
        type: 0,
        filePath: 'photos/middleton_family.jpg',
        description: 'Middleton family, 1930s or 1940s.',
        attribution: 'Clara M. Leigh'
    },
    {
        id: 153,
        type: 0,
        filePath: 'photos/summers_house_1985.jpg',
        description: 'Summers House, 1985.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        coordinates: [38.94121947521846, -77.42609381675722],
        subjectYear: 1860
    },
    {
        id: 154,
        type: 0,
        filePath: 'photos/wurzberg_nws_1.jpg',
        description: 'Wurzberg antennas, National Bureau of Standards, Sterling, Virginia.',
        attribution: 'NRAO/AUI/NSF',
        coordinates: [38.97896127226737, -77.46743202209474],
        subjectYear: 1947
    },
    {
        id: 155,
        type: 0,
        filePath: 'photos/janney_patton.png',
        description: 'Hillcrest, 1966.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        subjectYear: 1840
    },
    {
        id: 156,
        type: 0,
        coordinates: [38.9396422915986, -77.41385221481325],
        filePath: 'photos/rogers_family.jpg',
        description: 'Rogers Family',
        attribution: 'Courtesy of Susan Rogers MacAuley.',
        subjectYear: 1870
    },
    {
        id: 157,
        type: 0,
        coordinates: [38.91928596513094, -77.49335289001466],
        filePath: 'photos/pleasant_valley_one_room.jpg',
        description: 'Pleasant Valley School, 1910s',
        attribution: 'J. Berkley Green Collection of the Herndon Historical Society',
        subjectYear: 1891
    },
    {
        id: 158,
        type: 0,
        coordinates: [38.89463188591892, -77.43285298347475],
        filePath: 'photos/chantilly_crossroads_from_tractor_ssupply.jpg',
        description: 'Chantilly Crossroads, 1962',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        subjectYear: 1920,
        streetView: true,
        heading: 120
    },
    {
        id: 159,
        type: 0,
        coordinates: [38.894690337815284, -77.43330359458925],
        filePath: 'photos/route_50_west_chantilly_1962.jpg',
        description: 'Little River Turnpike, westbound, 1962',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        subjectYear: 1863,
        streetView: true,
        heading: -70
    },
    {
        id: 160,
        type: 0,
        coordinates: [38.894080765673785, -77.43073940277101],
        filePath: 'photos/chantilly_inn_facade.jpg',
        description: 'Chantilly Inn facade, 1962',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        subjectYear: 1920,
        streetView: true,
        heading: 20
    },
    {
        id: 161,
        type: 0,
        coordinates: [38.937664503830646, -77.41320848464967],
        filePath: 'photos/bowman_floris.jpg',
        description: 'Bowman Store and Floris Methodist Church along Centreville Road, 1900s.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        subjectYear: 1895,
        streetView: true,
        heading: 0
    },
    {
        id: 162,
        type: 1,
        coordinates: [38.93656292723165, -77.41100907325746],
        filePath: 'photos/floris_1911.jpg',
        description: 'Floris Schoolhouse, 1940s.',
        attribution: 'Courtesy of the Fairfax County Public Library Photographic Archive.',
        subjectYear: 1911,
        streetView: true,
        heading: 30
    }
];
